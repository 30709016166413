import { Button } from 'app/components/ui/button.component';
import { useTranslate } from 'app/hooks/lang';
import { NextPage } from 'next'
import Head from 'next/head';
import Link from 'next/link'
import React from "react";
import styles from "./404.module.scss";

const FourOhFour: NextPage = () => {
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <Head>
        <title>{t("seo_title_home")}</title>
      </Head>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <div data-navigate-row>
        <Button data-navigate-item type="submit">
          <Link href="/">
            {t("header.menu.home")}
          </Link>
        </Button>
      </div>
    </div>
  )
}

export default FourOhFour;